import React, { useRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import Seo from '../components/utils/Seo';
import Header from '../components/layout/Header';
import Cursor from '../components/utils/Cursor';
import Nav from '../components/modules/Nav';
import Error404 from '../components/pages/Error404';
import Loader from '../components/modules/Loader';

function NotFoundPage() {
	const containerRef = useRef(null);

	return (
		<>
			<Seo title="404: Not found" />
			<Header />
			<LocomotiveScrollProvider
				options={
					{
						smooth: true,
						direction: 'vertical',
					}
				}
				watch={
					[]
				}
				containerRef={containerRef}
			>
				<Nav />
				<Cursor />
				<main data-scroll-container ref={containerRef}>
					<Error404 />
				</main>
			</LocomotiveScrollProvider>
			<Loader />
		</>
	)
}

export default NotFoundPage
