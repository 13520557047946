import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import FooterPage from '../layout/FooterPage';
import Anchor from '../ui/Anchor';

function Error404() {
	const { scroll } = useLocomotiveScroll();

	useEffect(() => {
		if (scroll === null) {
			return;
		}

		setTimeout(() => {
			scroll.destroy();
			scroll.init();
		}, 1000);
	}, [scroll]);

	useEffect(() => {
		document.body.classList.remove('bg-white', 'bg-black', 'bg-brand-one', 'bg-home');
	}, []);

	return (
		<>
			<section id="erorr-404" className="error-404 section--fluid" data-scroll-section data-bg-colour="black">
				<div className="section-title" data-scroll data-scroll-speed="1" data-scroll-direction="vertical">
					<h2>404</h2>
				</div>
				<div className="container">
					<h1 className="error-404__title">Error: 404</h1>
					<ul className="error-404__menu">
						<li>
							<Anchor className="site-nav__button" url="/">Home</Anchor>
						</li>
						<li>
							<Anchor className="site-nav__button" url="/schedule">Schedule</Anchor>
						</li>
						<li>
							<Anchor className="site-nav__button" url="/about">About</Anchor>
						</li>
					</ul>
				</div>
			</section>
			<FooterPage />
		</>
	);
}

export default Error404;
